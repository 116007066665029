<template>
  <div class="container" id="container1">
    <div class="title">
      <div class="title-top" style="display:inline-block;">
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="文献名称">
              <el-input
                v-model="logisticsForm.name"
                placeholder="请输入要搜索的名称"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div
        class="title-bottom"
        style="display:inline-block;margin:.5vh 0;float:right;"
      >
        <div class="title-bottom-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="small" @click="removeForm"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <div class="nav">
      <div
        :class="index == 211 ? 'navItem active' : 'navItem'"
        @click="getIndex(211)"
      >
        <p class="navItemTitle">蔬菜类病虫</p>
      </div>
      <div
        :class="index == 212 ? 'navItem active' : 'navItem'"
        @click="getIndex(212)"
      >
        <p class="navItemTitle">农作物病虫</p>
      </div>
      <div
        :class="index == 213 ? 'navItem active' : 'navItem'"
        @click="getIndex(213)"
      >
        <p class="navItemTitle">粮食类病虫</p>
      </div>
      <div
        :class="index == 214 ? 'navItem active' : 'navItem'"
        @click="getIndex(214)"
      >
        <p class="navItemTitle">经济类病虫</p>
      </div>
      <div
        :class="index == 215 ? 'navItem active' : 'navItem'"
        @click="getIndex(215)"
      >
        <p class="navItemTitle">草虫害天敌</p>
      </div>
      <div
        :class="index == 216 ? 'navItem active' : 'navItem'"
        @click="getIndex(216)"
      >
        <p class="navItemTitle">水果类病虫</p>
      </div>
    </div>
    <div class="main">
      <div style="width: 100%; height: 71vh; overflow-y: auto">
        <div class="mainList" v-for="item in lists" :key="item.id">
          <p class="mainListName">{{ item.diseasesName }}</p>
          <div class="mainListIcon">
            <i
              class="el-icon-document"
              style="color: #00284d;"
              @click="getDetail(item.id)"
            ></i>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          :page-sizes="[50, 100, 150, 200]"
          :page-size="pageSize"
          :current-page="curPage"
          layout="sizes, prev, pager, next"
          @size-change="handleSizeChange"
          :total="dataTotal"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="diseasesTypes" :visible.sync="isShow" width="40%" center>
      <p class="detailTitle">{{ diseasesName }}</p>
      <p class="detailContent" v-html="diseasesContent"></p>
      <!-- <span>需要注意的是内容是默认不居中的</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
            </span> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      index: 211,
      lists: [],
      //弹框标题
      diseasesTypes: "",
      diseasesName: "",
      diseasesContent: "",
      curPage: 1,
      value1: "",
      pageSize: 10,
      // 总数
      dataTotal: 0,
      logisticsForm: {
        name: "",
        region: -1,
      },
    };
  },
  mounted() {
    this.getData(this.index);
  },
  methods: {
    search() {
    //   let name = this.logisticsForm.name;
      this.loading = true;
      this.curPage = 1;
      this.getData(this.index);
    },
    getIndex(index) {
      this.index = index;
      this.curPage = 1;
      this.pageSize = 10;
      this.getData(index);
    },
    getDetail(id) {
      this.isShow = true;
      let m = this.lists;
      for (let i = 0; i < m.length; i++) {
        if (id == m[i].id) {
          this.diseasesTypes = m[i].diseasesTypes;
          this.diseasesName = m[i].diseasesName;
          this.diseasesContent = m[i].diseasesContent;
        }
      }
    },
    changePage(page) {
      this.curPage = page;
      this.getData(this.index);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getData(this.index);
    },
    //重置
    removeForm() {
      this.logisticsForm.name = "";
      this.curPage = 1;
      this.getData(this.index);
    },
    // 搜索
    getData(type) {
      this.$get("/remoteSelfHelp/getDiagnoseData", {
        page: this.curPage,
        size: this.pageSize,
        type: type,
        where: this.logisticsForm.name,
      }).then((res) => {
        if (res.data.state == "success") {
          this.lists = res.data.datas;
          this.dataTotal = res.data.count;
        } else {
          this.$message({
            message: "没有数据",
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../style/backTable.less";
.container {
  width: 100%;
  height: 86vh;
  overflow: hidden;
  color: #333;
  font-size: 14px;
  padding: 1vh;
  box-sizing: border-box;
}
.nav {
  display: flex;
  width: 100%;
  height: 5vh;
  background: rgba(0, 40, 77, 0.6);
  font-size: 16px;
  color: #fff;
  margin-top: 1vh;
}
.navItem {
  flex: 1;
  text-align: center;
  cursor: pointer;
  line-height: 5vh;
  margin-left: 2vh;
}
.navItem:first-child {
  margin-left: 0 !important;
}
.active {
  background: rgba(0, 40, 77, 0.9) !important;
}
.main {
  width: 100%;
  height: 80vh;
  margin-top: 0.5vh;
}
.mainList {
  display: inline-block;
  width: 25%;
  height: 4vh;
  text-align: center;
  padding: 0 1%;
  margin-top: 1.5vh;
  box-sizing: border-box;
}
.mainListName {
  display: inline-block;
  width: 70%;
  vertical-align: middle;
  line-height: 4vh;
}
.mainListIcon {
  display: inline-block;
  width: 25%;
  vertical-align: middle;
  font-size: 24px;
  color: #68dab6;
  cursor: pointer;
}
.el-pagination {
  text-align: center;
  width: 84vw;
  position: absolute;
  bottom: 3vh;
}
</style>
<style>
#container1 .el-dialog__header {
  background: rgba(0, 40, 77);
}
#container1 .el-dialog__title {
  color: #fff !important;
}
#container1 .el-icon-close:before {
  content: "\E6DB";
  color: #fff;
}
</style>
